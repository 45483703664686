<template>
  <section>
    <a-row>
      <a-col :span="24">
        <a-result status="404" title="404" sub-title="Not Found ):">
          <template #extra>
            <router-link>
              <a-button type="primary" :to="{ name: 'Login' }">
                Back Home
              </a-button>
            </router-link>
          </template>
        </a-result>
      </a-col>
    </a-row>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
